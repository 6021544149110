.outer {
    align-items: center;
    background-color: #333; // #0948B3;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 90px;
    padding-right: 90px;
    @media (min-width: 768px) and (max-width: 991px) {
        margin: 10px 0 0px;
    }
    @media (max-width: 767px) {
        margin: 10px 0 0px;
    }
}


