.outer {
    margin:0;
    padding:0;
    box-sizing:border-box;
    display:inline-block;
}
.inner {
    color:rgb(37, 56, 88);
    border-color: rgb(244, 245, 247);
    border-radius:3px;
    cursor:default;
    display:flex;
    height:20px;
    line-height:1;
    margin:4px;
    padding:0px;
    overflow:initial;
    &:focus {box-shadow:rgb(76, 154, 255) 0px 0px 0px 2px;outline:none;}
    &:hover {box-shadow:none;background-color:rgb(235, 236, 240);color:rgb(37, 56, 88);}
}
.text {
    font-size:14px;
    font-weight:normal;
    line-height:1;
    margin-left:4px;
    margin-right:4px;
    padding:2px 0px;
    max-width:160px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.remove {
    -webkit-box-align:center;
    align-items:center;
    align-self:center;
    -webkit-appearance:none;
    background:none;
    border:none;
    border-radius:3px;
    color:rgb(66, 82, 110);
    display:flex;
    -webkit-box-pack:center;
    justify-content:center;
    height:16px;
    margin:0px;
    padding:0px;
    &:focus {
        box-shadow:rgb(255, 86, 48) 0px 0px 0px 2px;
        outline:none;
    }
    &:hover {
        color:rgb(191, 38, 0);
    }
    &:hover:focus {
        box-shadow:rgb(76, 154, 255) 0px 0px 0px 2px;
        outline:none;
    }
}
.remove2 {
    height:16px;
    width:16px;
    display:inline-block;
    flex-shrink:0;
    line-height:1;
    & > svg {
        height:16px;
        width:16px;
        max-height:100%;
        max-width:100%;
        overflow:hidden;
        pointer-events:none;
        vertical-align:bottom;
    }
}
