.header {
    align-items: center;
    background-color: #333; // #0948B3;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    line-height: 24px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 85px;
    padding-right: 55px;
    input {
        background-color: #fff;
        display:block;
        input:focus {
            background-color: #fff;
        }
    }
    a, a:hover, a:visited, a:link, a:active {
        box-sizing: border-box;
        text-decoration: none;
    }
}

.brand {
    margin-right: 45px;
    @media (max-width: 767px) {
        display: none;
    }
}

.brandItems {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    line-height: 24px;
}

.brandName {
    color: #fff;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-left: 30px;
}

.search {
    font-family: "Nunito Sans", sans-serif;
    width: 100%;
    font-size: 16px;
}

.rotateLogo {
    transition: all 0.1s cubic-bezier(0.55, 0.09, 0.88, 0.39);

    &:hover {
        transform: scale(1.1);
    }
}

.settingsIcon {
    color: white;
}