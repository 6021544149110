.outer {
    background-color: #fff;
    margin-top: 20px;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 45px;
    margin-bottom: 30px;
    @media (max-width: 767px) {
        padding-bottom: 48px;
        padding-top: 35px;
    }
}

.tabArea {
    padding-top: 62px;
}
