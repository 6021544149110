.outer {
    font-family: "Poppins",sans-serif;
    padding-left: 27px;
    & h1 {
        font-family: Roboto,sans-serif;
        font-size: 34px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 21px;
    }
    @media (max-width: 767px) {
        padding: 40px 0 0;
    }
    @media (min-width: 768px) and (max-width: 991px) { 
        padding: 0;
    }    
}
.pdButtons {
    border-top: 1px solid #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 30px 0 19px;
    & > a, & > button {
        background: #e3e3e3 none repeat scroll 0 0;
        color: #333333;
        display: block;
        font-size: 14px;
        font-weight: 700;
        line-height: 40px;
        margin-right: 3px;
        padding: 0 16px;
        text-transform: uppercase;
    }
    & > a:hover, & > button:hover {
        background: #bda87f none repeat scroll 0 0;
        color: #ffffff;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
        display: flex;
        & > a, & > button {margin: 10px 5px 10px 0; width: auto;}
    }
    @media (max-width: 767px) {
        display: block;
        padding: 18px 0 13px;
        text-align: center;
        & > a, & > button {
            float: none;
            margin: 10px auto;
            width: 100%;
        }
    }
    @media (min-width: 768px) and (max-width: 991px) { 
        & > a:last-child, & > button:last-child {
            margin: 0;
        }
        & > a, & > button {
            padding: 0 14px;
        }
    }
}