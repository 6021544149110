.header {
    align-items: center;
    background-color: #333; // #0948B3;
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 90px;
    padding-right: 100px;
    a, a:hover, a:visited, a:link, a:active {
        text-decoration: none;
        color: #fff;
    }
    transition: all 0.1s cubic-bezier(0.55, 0.09, 0.88, 0.39);

    a:hover {
        transform: scale(1.1);
    }
}

.prev {
    margin-left: 45px;
}

.next {
    margin-left: 45px;
}
