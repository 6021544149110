.outer {
    position: relative;
    margin-bottom: 24px;
    @media (max-width: 767px) {
        padding: 10px 0 11px;
        :global(.col-lg-3):last-child &:last-child {
            padding-bottom: 0;
        }
    }
}

.clickable {
    display: block;
    position: relative;
}

.clickableLoading {
    &:after {
        content: " ";
        display: block;
        padding-bottom: 100%;
    }
}

.clickableLoading2 {
    position: absolute;
    left:50%;
    top:50%;
    margin-left: -20px;
    margin-top: -20px;
}
