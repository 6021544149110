.outer {
    border: 1px solid #e5e5e5;
    padding: 15px;
    @media (max-width: 767px) {
        padding-top: 0;
        & :global(.tab-items) {
            padding: 20px 0 0;
        }
    }
}

.pdTabButtons {
    border-bottom: 1px solid #e5e5e5;
    & :global(.nav) a {
        color: #7d7d7d;
        font-size: 14px;
        font-weight: 600;
        line-height: 25px;
        margin-right: 25px;
        text-transform: uppercase;
    }
    & :global(.nav) {
        padding-bottom: 6px;
    }
    & :global(.nav) a:global(.active) {color: #222222;}
}

.pdTabItems {
    padding: 20px;
}

.pdDetailsList {
    & > p {
        margin-bottom: 9px;
    }
    & > span {
        display: block;
        line-height: 21px;
        margin-bottom: 9px;
        margin-left: 40px;
        position: relative;
    }
    & > span:after {
        background: #474747 none repeat scroll 0 0;
        border-radius: 50%;
        content: "";
        height: 5px;
        left: -12px;
        position: absolute;
        top: 8.5px;
        width: 5px;
    }
    @media (max-width: 767px) {
        & > span {
            margin-left: 15px;
        }
    }
}
